import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { HomePageQueryQuery } from '../../graphql-types';

import TagListContainer from '../containers/TagList';

import Layout from '../components/Layout';
import Checklists from '../components/Checklists';
import Pagination from '../components/Pagination';
import Icon from '../components/Icon';
import LinkLightAsAnchor from '../components/links/LinkLight/LinkLightAsAnchor';

import './o-checklists-heading.scss';
import './o-suggest-a-checklist.scss';
import './o-page-container.scss';

interface HomePageContext {
  totalPages: number;
  currentPage: number;
}

interface Props {
  data: HomePageQueryQuery;
  pageContext: HomePageContext;
}

const Home: FC<Props> = ({ data, pageContext }) => {
  const { checklists } = data;

  return (
    <Layout>
      <div className="o-page-container">
        <TagListContainer
          className="u-margin-bottom-xlarge@lg-up u-margin-bottom@md-down"
          limit={36}
        />

        <div className="o-checklists-heading">
          <h2 className="u-margin-bottom-0">Community Curated Checklists</h2>

          <LinkLightAsAnchor
            className="o-suggest-a-checklist"
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/checkops/checklists/new/master/checklists?filename=checklists/change-this-filename.md&value=---%0Atitle%3A%20Title%20of%20Your%20Checklist%0Acategory%3A%20Change%20this%20value%20with%20one%20of%20them%3B%20%22Design%22%2C%20%22Front-End%22%2C%20%22Back-End%22%2C%20%22Mobile%22%2C%20%22DevOps%20%22General%22%0Adate%3A%20%22YYYY-MM-DD%22%0Atags%3A%20[%27update%27%2C%20%27with%27%2C%20%27your%27%2C%20%27own%27%2C%20%27good-tags%27]%0Adescription%3A%20Please%20give%20a%20description%20to%20your%20checklist%0A---%0A%0A-%20[%20]%20Provide%20a%20to-do%20%20%0APlease%20add%20a%20description%20to%20this%20to-do%20if%20necessary%0A%0A-%20[%20]%20Second%20step"
          >
            Add New{' '}
            <Icon className="o-suggest-a-checklist__icon" name="external-link" ariaHidden />
          </LinkLightAsAnchor>
        </div>
        <div className="u-text-style-large-body u-margin-top-xsmall">
          There are {checklists.totalCount} of them and counting!
        </div>
        <Checklists className="u-margin-top-small" items={checklists.nodes} />
        <Pagination totalPages={pageContext.totalPages} currentPage={pageContext.currentPage} />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query HomePageQuery($skip: Int!, $limit: Int!) {
    checklists: allMarkdownRemark(
      sort: { fields: [frontmatter___featured, frontmatter___date], order: [ASC, DESC] }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        ...Checklist
      }
    }
  }
`;

export default Home;
